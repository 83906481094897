var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("br"),
      _vm._m(0),
      _c("br"),
      _c("br"),
      _c("q-table", {
        staticClass: "my-sticky-header-table no-box-shadow q-mt-none",
        attrs: {
          title: "Elenco dei pagamenti fatti dal Contraente",
          data: _vm.rows,
          columns: _vm.colonne,
          filter: _vm.filter,
          separator: "cell",
          "row-key": "id",
          flat: "",
          "visible-columns": _vm.visibleColumns,
        },
        on: { "row-click": _vm.selezionaRecordPagamenti },
        scopedSlots: _vm._u([
          {
            key: "top-right",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "panel-cerca" },
                  [
                    _c("q-input", {
                      staticStyle: { "min-width": "350px" },
                      attrs: {
                        outlined: "",
                        dense: "",
                        debounce: "300",
                        placeholder: "Cerca",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "append",
                          fn: function () {
                            return [_c("q-icon", { attrs: { name: "search" } })]
                          },
                          proxy: true,
                        },
                      ]),
                      model: {
                        value: _vm.filter,
                        callback: function ($$v) {
                          _vm.filter = $$v
                        },
                        expression: "filter",
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function (props) {
              return [
                _c(
                  "q-tr",
                  {
                    staticStyle: { cursor: "pointer" },
                    attrs: { props: props },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.selezionaRecordPagamenti(null, props.row)
                      },
                    },
                  },
                  [
                    _c("q-td", { attrs: { "auto-width": "" } }, [
                      _vm._v(" " + _vm._s(props.cols[0].value) + " "),
                    ]),
                    _c("q-td", { attrs: { "auto-width": "" } }, [
                      _vm._v(" " + _vm._s(props.cols[1].value) + " "),
                    ]),
                    _c("q-td", { attrs: { "auto-width": "" } }, [
                      _vm._v(" " + _vm._s(props.cols[2].value) + " "),
                    ]),
                    _c("q-td", { attrs: { "auto-width": "" } }, [
                      _vm._v(" " + _vm._s(props.cols[3].value) + " Euro "),
                    ]),
                    _c(
                      "q-td",
                      { attrs: { "auto-width": "", align: "center" } },
                      [
                        _c("q-btn", {
                          attrs: { color: "red", label: "Stampa ricevuta" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c("br"),
      _c("br"),
      _c("hr"),
      _c("div", { staticClass: "row justify-center" }, [
        _c(
          "div",
          { staticClass: "col-md-4", attrs: { align: "center" } },
          [
            _c("QQButton", {
              attrs: {
                label: "INDIETRO",
                color: "blue-grey",
                icon: "undo",
                size: "md",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onClickIndietro()
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { align: "center" } }, [
      _c("span", { staticClass: "text-h4" }, [_vm._v("Elenco dei pagamenti")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }